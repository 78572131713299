'use client'
import { FC } from 'react'
import { Contact, IContactProps } from 'storefront-modules/contact'
import { useModal } from '#/src/custom/controllers'

export interface CustomContactProps extends Omit<IContactProps, 'apiPath' | 'sectionId'> {}

const CustomContact: FC<CustomContactProps> = ({ ...props }) => {
  const { openModal } = useModal()

  const onSuccess = () => {
    openModal({
      title: 'Request sent',
      componentId: 'confirmation',
      size: 'md'
    })
  }

  return (
    <Contact
      apiPath="/api/contact/send-contact"
      sectionId="contact"
      {...{ onSuccess }}
      {...props}
    />
  )
}

export default CustomContact
